import Layout from '../components/UI/Layout';
import type { ContentType } from 'contentful';

import cmsClient from '../lib/cms/client';
import hydrateTemplate from '../lib/cms/links';
import {
  BrandedSiteProps,
  getServerSidePropsWithErrors,
  getContentfulPageData,
  toSlug,
  cacheDirective,
  PageDates,
  getContentfulSiteData,
  isInvalid,
  withSiteData,
} from '../lib/routing';
import { ReactElement } from 'react';
import { IPageFields } from 'types/contentful';
import {
  buildDynamicComponent,
  DynamicComponentTypes,
  PreparedComponentObject,
  translateServerSideComponent,
} from 'components';

type SluggedProps<K extends DynamicComponentTypes = DynamicComponentTypes, CP = unknown> = {
  preview: boolean;
  content: PreparedComponentObject<K, CP>[];
  siteData: BrandedSiteProps;
  currentSlug: string;
  title: string;
  description: string;
  noIndexNoFollow: boolean;
  pageDates?: PageDates;
  hideTopNavCtas?: boolean;
};

export default function Slugged<K extends DynamicComponentTypes, CP>({
  preview,
  title,
  description,
  currentSlug,
  content,
  siteData,
  noIndexNoFollow,
  pageDates,
  hideTopNavCtas,
}: SluggedProps<K, CP>): ReactElement {
  const components = content.map((comp: PreparedComponentObject<K, CP>, index: number) => {
    const dynamicComp = buildDynamicComponent(comp, siteData.siteName, preview);
    if (dynamicComp === undefined) return;

    return <div key={index}>{dynamicComp}</div>;
  });

  const nextSeoProps = {
    description,
    title,
  };

  return (
    <Layout
      noIndexNoFollow={noIndexNoFollow}
      siteData={siteData}
      preview={preview}
      currentSlug={currentSlug}
      nextSeoProps={nextSeoProps}
      pageDates={pageDates}
      hideTopNavCtas={hideTopNavCtas}
    >
      <div>{components}</div>
    </Layout>
  );
}

type ToRecord<T> = Record<keyof T, T[keyof T]>;

export const getServerSideProps = getServerSidePropsWithErrors<SluggedProps>(
  async ({ resolvedUrl, params, req, res, preview }) => {
    const slug = toSlug(params?.slug) || '';
    const queryOptions = {
      'fields.site.sys.contentType.sys.id': 'site',
      'fields.site.fields.id': req.headers.host,
      'fields.slug': slug || 'home',
      content_type: 'page',
      include: 10, // Make sure we retrieve nav with links
    };

    const siteData = await getContentfulSiteData({ req, res, resolvedUrl, preview });

    if (isInvalid(siteData)) {
      return siteData;
    }

    const contentfulData = await getContentfulPageData<IPageFields>({
      res,
      req,
      preview,
      queryOptions,
    });

    if (isInvalid(contentfulData)) {
      return withSiteData(contentfulData, siteData);
    }

    const client = cmsClient(preview);

    const pageMeta = contentfulData.item.fields;

    // fetch template
    const rawTemplate: ContentType = await client.getContentType(
      pageMeta.template.sys.contentType.sys.id
    );

    // remove symbol fields
    const templateLayout = rawTemplate.fields.filter((field) => field.type !== 'Symbol');

    // hydrate and build out template requirements
    const hydrated = await Promise.all(
      hydrateTemplate(
        templateLayout,
        pageMeta.template.fields as ToRecord<typeof pageMeta.template.fields>,
        contentfulData.includes?.Asset || [],
        contentfulData.includes?.Entry || []
      ).map((item) => translateServerSideComponent(item, !!preview))
    );

    res.setHeader('Cache-Control', cacheDirective(preview));

    return {
      props: {
        noIndexNoFollow: !!pageMeta.doNotIndex,
        preview: !!preview,
        siteData,
        title: pageMeta.title || '',
        description: pageMeta.description || '',
        content: hydrated,
        currentSlug: slug,
        pageDates: {
          datePublished: contentfulData.item.sys.createdAt,
          dateModified: contentfulData.item.sys.updatedAt,
        },
        hideTopNavCtas: pageMeta.hideTopNavCtas || false,
      },
    };
  }
);
